import {Col, Row, Container, Button, Card} from 'react-bootstrap';



export default function OurServices(servicesProp) {

const { serviceProp } = servicesProp
const {imgUrl, title, description} = serviceProp

	return(
			<Col xs={12} md={6} lg={3}>
						<Card className=" text-center"
						style={{backgroundColor: " #080548", border: "none", color: "white", textAlign: "center" }}>
					    <Card.Body>
				        <Card.Img 
				        src={imgUrl}
				        style={{ width: "150px", height: "150px", padding: "10px",margin: "5%"}}
				         />	
					 	<h1 className="Services-title">{title}</h1>
							<Card.Text className="cardParagraph">{description}</Card.Text>								
					    </Card.Body>
				        </Card>
			</Col>

		)
}
