import { Container } from 'react-bootstrap'

export default function WhoWeAre() {
	
	return (

	<Container className="text-center WhoWeAre-container">
      <h1 className="WhoWeAre pb-0">
          We provide <span className="Highlight">DIGITAL </span>
          solutions to businesses who want to succeed by strengthening their
          online presence and sales by creating platforms and managing their
          online marketing.
      </h1>
    </Container>
		
	)
}