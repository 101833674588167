import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import '../Styles/Cookies.css'

export default function Cookies (props) {

	return (

			 <Modal show={props.show} size="lg" >
		        <Modal.Body>
		        	<p><strong>Cookie Notification:</strong><br/><br/>
		        	We use cookies to analyze website traffic and optimize your website experience. 
		        	By accepting our use of cookies, your data will be aggregated with all other user data. 
		        	To know more, check our <a className="text-decoration-none" href="www.google.com">Cookie Policy.</a></p>
		        </Modal.Body>
		        <Modal.Footer>
		          <Button className="btn-cookies2" onClick={props.onHide}>
		            Decline
		          </Button>
		          <Button className="btn-cookies" onClick={props.consentProp}>
		            Accept
		          </Button>
		        </Modal.Footer>
		      </Modal>     
			// <div show={props} className="cookies">
			// 	<div className="cookies-content">
			// 		<div className="cookies-header">
			// 			<h4 className="modal-title">Modal Title</h4>
			// 		</div>
			// 		<div className="cookies-body">
			// 			MOdal Content
			// 		</div>
			// 		<div className="cookies-footer">
			// 			<button onClick={props.onHide} className="button" >Close</button>
			// 		</div>
			// 	</div>
			// </div>
		)
}