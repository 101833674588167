import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import WebFont from "webfontloader";


WebFont.load({
  google: {
    // families: ["Poppins", "Open Sans:400"],
    families: ["Poppins", "Inter:700", "Montserrat:700,400"],

  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


