
const formats = [
    [
        {className:"ql-bold"},{className:"ql-italic"},{className:"ql-underline"}
    ],
    [
        {
            className:'ql-align',
            value: 'right'
        },
                {
            className:'ql-align',
            value: 'center'
        },
                {
            className:'ql-align',
            value: 'justify'
        }
    ]
]

export default formats