import {Col, Row, Container, Button, Card} from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoingUp from '../images/GoingUP.png'

export default function Banner() {

const navigate = useNavigate()
	

	return (
		<div id="home">
          <div className="going-up pb-0">
            <div>
            	<h1 className="BannerText"> 
            		Simplified <div className="Highlight">digital</div> solutions for your growing business. 
            	</h1>
                <Button className="BannerButton" href="/#contactUs">
              		GET A QUOTE
              	</Button>
            </div>
              <img className="goingUp-img" src={GoingUp}/>
          </div>        
      </div>

		)
}

