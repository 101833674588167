import { useState, useEffect } from 'react';
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import AppNavBar from './components/AppNavBar'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Footer from './components/Footer'
import {BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom'; 
import { Container } from 'react-bootstrap'
import './App.css';



// FireBase imports
import { db } from './firebase-config'
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { async } from '@firebase/util';

function App() {
  
  const [users, setUsers] = useState([])
  const [newEmail, setNewEmail] = useState("")
  const usersCollectionRef = collection(db, "users")

  // Creating a new data in DB
  const createUser = async () => {
    await addDoc(usersCollectionRef, { email: newEmail } )
  }
  
  // Updating a document
    const updateUser = async (id, city) => {
      const userDoc = doc(db, "users", id)  
      const newFields = {city: city}
      await updateDoc(userDoc, newFields)
    }

  // Deleting a document
  const deleteUser = async (id) => {
    const userDoc = doc(db, "users", id);
    await deleteDoc(userDoc); 
  } 

  useEffect(() => {

    const getUsers = async () => {
       const data = await getDocs(usersCollectionRef)
       // ... is a spreading operator for JS in order to add fields such as id
       setUsers(data.docs.map((doc) => ({...doc.data(), id: doc.id}))); 
    }
    getUsers()
  }, [] )


  return (

    // <div className="App"> 
    // {users.map((user) => { 
    //   return (
    //     <div>
    //       {" "}
    //       <h1>Email: {user.email} </h1>
    //       <h1>Contacts: {user.contacts.city}</h1>
    //     </div>
    //     );
    // })}
    // </div>

    <Router>
    <AppNavBar/>
      <div style={{paddingBottom: "0px"}}>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="*" element={<NotFound/>}/>
            <Route path="/data-privacy" element={<PrivacyPolicy/>}/>
          </Routes>
      </div>
    <Footer/>
    </Router>

  );
}

export default App;
