import { Container, Component, Col, Form, Row, Button  } from 'react-bootstrap';

// For sweet alert
import Swal from 'sweetalert2';

import React, { useState, useEffect, useRef } from 'react';

// For EmailJS
import emailjs from '@emailjs/browser';

// For IP Geolocation
import axios from 'axios';

import  ReactQuill, { Quill, editor }  from  "react-quill";
import  "react-quill/dist/quill.snow.css";
import CustomToolbar from '../components/CustomToolbar';

// FireBase imports
import { db } from '../firebase-config'
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc, setDoc, arrayUnion } from 'firebase/firestore'
import { async } from '@firebase/util';




export default function ContactUs(consent) {
	
	const { consentProp } = consent	

  const [userCookie, setUserCookie] = useState("") 

  // Getting and Setting values for each text fields
  const [getName, setName] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getConcern, setConcern] = useState("");
  const [getMessage,setMessage] = useState("");
  const [getTerms, setTerms] = useState(false)
  const [waitTerms, setWaitTerms] = useState(true)
  // For validation

  const [getValidName, setValidName] = useState(true);
  const [getValidEmail, setValidEmail] = useState(true);
  const [getValidConcern, setValidConcern] = useState(true);
  const [getValidInquiry, setValidInquiry] = useState(true);
  const [getValidMessage, setValidMessage] = useState(true);

  // For Geolocation
  const [ipAddress, setIpAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  var url = "https://ipgeolocation.abstractapi.com/v1/?api_key=541a7c8e44104a8191dacfaf28ce6d04";

  // For FireBase
  const usersCollectionRef = collection(db, "users")

  const createUser = async () => {
    await addDoc(usersCollectionRef, 
    	{ 
    		email: getEmail,
    		createdAt: Date(),
    		contacts: [
    			{
    				website: "PDBSI-Official",
    				concern: getConcern,	
    				country: country,
    				city: city,
    				ipAddress: ipAddress,
    				createdAt: Date()
    			}
    		]

    	})

  }


	const findUser = async () => {
       const data = await getDocs(usersCollectionRef)

       let object = {};

       const allUserEmail = data.docs.map((doc) => ({ id: doc.id, ...doc.data()})); 
       allUserEmail.map((data) => {
       	if(data.email === getEmail){
       		object = {
       			emailExist: true,
       			id: data.id
       		}
       	 }  
       })

       return object

    }






  // For EmailJs
  const form = useRef();
  
  // For Refreshing page 
  function refreshPage() {
 	   setTimeout(function(){
	   window.location.reload();
	}, 5000);
  }

  // Validation
  const validate = () => {
		const arr = [getEmail, getConcern, getName, getMessage];
		const error = [];
	 arr.forEach(function (task) {
	    if(task !== false){
	    error.push(task); 
	    }
	});
		 emptyfields(error);
	}

	const emptyfields = (error) => {
		for(let i = 0; i < error.length; i++){
			console.log(error)
			
			// Empty Fields 
			if(0 === i && error[i] === ""){
				setValidEmail(false)

			} else if (1 === i && error[i] === "") {
				setValidConcern(false)

			} else if(2 === i && error[i] === "") {
				setValidName(false)

			} else if (3 === i && (error[i] === "" || error[i] === '<p><br></p>')) {

				setValidMessage(false)
			} 

			// If fields has a value
				else if (0 === i && error[i] !== "") {
				setValidConcern(true)

			}
				else if (1 === i && error[i] !== "") {
				setValidConcern(true)

			} else if(2 === i && error[i] !== "") {
				setValidName(true)
 
			} else if (3 === i && (error[i] !== ""  || error[i] !== '<p><br></p>')) {

				setValidMessage(true)
			}
		}
	}	

// For Terms and Condtions Checkbox
function checkBox(value){
	if (value === true){
		setWaitTerms(false)
		setTerms(value)
	} else {
		setWaitTerms(true)
		setTerms(value)
	}
} 	

// Cookies
    const cookieStorage =  {
      getItem: (key) => {
        const cookies = document.cookie
        .split(';')
        .map(cookie =>  cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value}), {});
        return cookies[key];
      },
      setItem: (key, value) => {
        document.cookie = `${key}=${value}; expires=Thu, 30 Dec 2027 12:00:00 UTC";`;
      },
    }

    const storageType = cookieStorage;
    const userPropertyName =  'User';
    const user = storageType.getItem("User")


// For Submitting the Email
function submitEmail(e) {
    e.preventDefault()
    if(getEmail !== "" && getMessage !== "" && getMessage !== "<p><br></p>" && getConcern !== "" && getName !== ""){

    				findUser().then(async (boolean) => {
    					if(boolean.emailExist !== true){
    					await createUser()
		    			await emailjs.sendForm('service_52dh0dv', 'template_e4kl58l', form.current, 'Ir_zhpYD60z07DzeK')
					      .then((result) => {
					      		//  Send Auto Reply
					    emailjs.sendForm('service_52dh0dv', 'template_51rzmtd', form.current, 'Ir_zhpYD60z07DzeK')
					      .then((outcome) => {

													    	let timerInterval;
													    	const Toast = Swal.mixin({
															  // toast: true,
															  position: 'center',
															  showConfirmButton: false,
															  timer: 3000,
															  timerProgressBar: false,

															  didOpen: (toast) => {
															    toast.addEventListener('mouseenter', Swal.stopTimer)
															    toast.addEventListener('mouseleave', Swal.resumeTimer)
															    // const b = Swal.getHtmlContainer().querySelector('b')
															    // timerInterval = setInterval(() => {
															    //   b.textContent = Swal.getTimerLeft()
															    // }, 100)
															  },

														      willClose: () => {
															    // window.location.reload()
															  }
															})
															   Toast.fire({
																	  icon: 'success',
																	  title: 'Email Submitted!'
																	})
															
				
											    	})
													    // Reset Values
													    setEmail("")
													    setConcern("")
													    setName("")
													    setMessage("")
													    setValidEmail(true)
													    setValidConcern(true)
														setValidName(true) 
														setValidMessage(true)

					       }, (error) => {
					          console.log(error.text);
					          const Toast = Swal.mixin({
									  // toast: true,
									  position: 'center',
									  showConfirmButton: false,
									  timer: 3000,
									  timerProgressBar: false,
									  didOpen: (toast) => {
									    toast.addEventListener('mouseenter', Swal.stopTimer)
									    toast.addEventListener('mouseleave', Swal.resumeTimer)
									  }
									})
					          				Toast.fire({
									  icon: 'error',
									  title: 'Something went wrong'
									})
					      });	
    				}
    				if(boolean.emailExist === true){
    					const userDoc = doc(db, "users", boolean.id) 

    					const data = {
						  	contacts: {
				    				website: "PDBSI-Official",
				    				concern: getConcern,	
				    				country: country,
				    				city: city,
				    				ipAddress: ipAddress,
				    				createdAt: Date()
				    			}	
						}    

					    await updateDoc(userDoc, {
						    contacts: arrayUnion(data.contacts)
						});

						await emailjs.sendForm('service_52dh0dv', 'template_e4kl58l', form.current, 'Ir_zhpYD60z07DzeK')
					      .then((result) => {
					      		//  Send Auto Reply
					    emailjs.sendForm('service_52dh0dv', 'template_51rzmtd', form.current, 'Ir_zhpYD60z07DzeK')
					      .then((outcome) => {

													    	let timerInterval;
													    	const Toast = Swal.mixin({
															  // toast: true,
															  position: 'center',
															  showConfirmButton: false,
															  timer: 3000,
															  timerProgressBar: false,

															  didOpen: (toast) => {
															    toast.addEventListener('mouseenter', Swal.stopTimer)
															    toast.addEventListener('mouseleave', Swal.resumeTimer)
															    // const b = Swal.getHtmlContainer().querySelector('b')
															    // timerInterval = setInterval(() => {
															    //   b.textContent = Swal.getTimerLeft()
															    // }, 100)
															  },

														      willClose: () => {
															    // window.location.reload()
															  }
															})
															   Toast.fire({
																	  icon: 'success',
																	  title: 'Email Submitted!'
																	})
															
				
											    	})
													    // Reset Values
													    setEmail("")
													    setConcern("")
													    setName("")
													    setMessage("")
													    setValidEmail(true)
													    setValidConcern(true)
														setValidName(true) 
														setValidMessage(true)

					       }, (error) => {
							  console.log(error)
					          console.log(error.text);
					          const Toast = Swal.mixin({
									  // toast: true,
									  position: 'center',
									  showConfirmButton: false,
									  timer: 3000,
									  timerProgressBar: false,
									  didOpen: (toast) => {
									    toast.addEventListener('mouseenter', Swal.stopTimer)
									    toast.addEventListener('mouseleave', Swal.resumeTimer)
									  }
									})
					          				Toast.fire({
									  icon: 'error',
									  title: 'Error occured'
									})
					      });	
    				}
    			})


    } else {
    	const Toast = Swal.mixin({
				  // toast: true,
				  position: 'center',
				  showConfirmButton: false,
				  timer: 3000,
				  timerProgressBar: false,
				  didOpen: (toast) => {
				    toast.addEventListener('mouseenter', Swal.stopTimer)
				    toast.addEventListener('mouseleave', Swal.resumeTimer)
				  }
				})

				Toast.fire({
				  icon: 'error',
				  title: 'Please check fields'
				})
				validate()
            } 
   }




   // Contents of Toolbar
    const handleChange= (html)=> {
        setMessage(html);
    }
    const modules = {
        toolbar: {
            container: "#toolbar",
        }
    }
    const formats = [
      'font','size',
      'bold','italic','underline','strike',
      'color','background',
      'script',
      'header','blockquote','code-block',
      'indent','list',
      'direction','align',
      'link','image','video','formula',
    ]

 useEffect(() => {
 	  // For IP Geolocation
 	  var xmlHttp = new XMLHttpRequest();
    xmlHttp.responseType = 'json';
    var data = xmlHttp.response
		xmlHttp.onreadystatechange = function(data) {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200){
        setIpAddress(xmlHttp.response.ip_address);
				setCountry(xmlHttp.response.country);
				setCity(xmlHttp.response.city);
        }
        // This commnet is to show all data from xmlHttp
			// console.log(xmlHttp.response)			
    }	
        xmlHttp.open("GET", url, true); // true for asynchronous
    	xmlHttp.send(null);


    	const user = storageType.getItem("User")
    	setUserCookie(user)
        const consent = storageType.getItem("PDBSI_consent")

            if (consent == undefined) {
		      setTerms(false)
		      setWaitTerms(true)
		    } else {
		      setTerms(true)
		      setWaitTerms(false)
		    }

},[])

	return (

		<div className=" pt-5">
		  <div id="contactUs" className="contact-us">
				<Container className="py-3" >
					<h2 className="text-center"><strong>CONTACT US:</strong></h2>
								<Form className="custom-padding" ref={form} onSubmit={(e) => submitEmail(e)}>
							      <Form.Group as={Row} className="mb-3" >
							        <Form.Label column md="3">
							          Name:
							        </Form.Label>
							        <Col md="9">
									          {

									          	getValidName == false ?
									          	<>
									          		<Form.Control 
											          style={{borderColor:"red",borderWidth: "2px",boxShadow: "none"}}   
											          type="text" 
											          placeholder="Enter Name"
											          value={getName}
											          name="user_name" 
											          onChange={e => {
								                 		 setName(e.target.value)
								                	  }}		           
								                	  />
								                <span style={{fontSize: "12px", color: "red", padding: "10px 20px"}}>* Please enter your name</span>	  
								               </>
								               :
								               	<Form.Control 
											          style={{borderColor:"#000000",borderWidth: "2px",boxShadow: "none"}}   
											          type="text" 
											          placeholder="Enter Name"
											          value={getName}
											          name="user_name" 
											          onChange={e => {
								                 		 setName(e.target.value)
								                	  }}		           
								               	/>

						                }
							        </Col>
							      </Form.Group>


							      <Form.Group as={Row} className="mb-3">
							        <Form.Label column sm="3">
							          Email Address:
							        </Form.Label>
									        <Col sm="9">
											        {

											        	getValidEmail == false ?
											        	<>
											        	<Form.Control 
											          style={{borderColor:"red",borderWidth: "2px",boxShadow: "none"}} 
											          type="email" 
											          placeholder="Enter Email Address"
											          value={getEmail}
											          name="user_email" 
											          onChange={e => {
								                 		 setEmail(e.target.value)
								                	  }} 
											          />
											          <span style={{fontSize: "12px", color: "red", padding: "10px 20px"}}>* Please enter your email</span>
											          </>
											          :
											          <Form.Control 
											          style={{borderColor:"#000000",borderWidth: "2px",boxShadow: "none"}} 
											          type="email" 
											          placeholder="Enter Email Address"
											          value={getEmail}
											          name="user_email" 
											          onChange={e => {
								                 		 setEmail(e.target.value)
								                	  }} 
											          />

											        }
									        </Col>
							      </Form.Group>


							      <Form.Group as={Row} className="mb-3">
							        <Form.Label column sm="3">
							          Concern:
							        </Form.Label>
											     <Col sm="9">
											         {

													         getValidConcern == false ?
													        <> 
													        <Form.Select 
													          style={{borderColor:"red",borderWidth: "2px",boxShadow: "none"}}  
													          aria-label="Default select example"
													          onChange={e => {
										                 		 setConcern(e.target.value)
										                	  }}
										                placeholder="Option"
													          >
															      <option>Select</option>
															      <option value="Web Development">Web Development</option>
															      <option value="Video and Photo Production">Video and Photo Production</option>
															      <option value="Social Media Management">Social Media Management</option>
															      <option value="Community Management">Community Management</option>
															      <option value="Others">Others</option>
														   		</Form.Select>
														   		<span style={{fontSize: "12px", color: "red", padding: "10px 20px"}}>* Please select your concern</span>
														   		</>
														    :
														    	<Form.Select 
													          style={{borderColor:"#000000",borderWidth: "2px",boxShadow: "none"}}  
													          aria-label="Default select example"
													          name="concern"
													          onChange={e => {
										                 		 setConcern(e.target.value)
										                	  }}
										                placeholder="Option"
													          >
														        <option>Select</option> 
															      <option value="Web Development">Web Development</option>
															      <option value="Video and Photo Production">Video and Photo Production</option>
															      <option value="Social Media Management">Social Media Management</option>
															      <option value="Community Management">Community Management</option>
															      <option value="Others">Others</option>
														   	 </Form.Select>

												  		}
											   </Col>
							      </Form.Group>


							      <Form.Group  as={Row} className="mb-3">
							        <Form.Label column sm="3">
							          Inquiry:
							        </Form.Label>
									        <Col sm="9">										        
											       {
											       		getValidMessage == false ?
											       		<>
											       		<div style={{border: "2px solid red", borderRadius: "8px"}}>
													        <ReactQuill
													          value={getMessage}
													          onChange={handleChange}
													          modules={modules}
													          formats={formats}
													          placeholder="How can we help you?"
													          style={{minHeight: "150px"}}
													        />
													        <CustomToolbar/>
													       </div> 
													       <span style={{fontSize: "12px", color: "red", padding: "10px 20px"}}>* Please enter your inquiry or message</span>
													       </>
													       :
													       <div style={{border: "2px solid", borderRadius: "8px"}}>
													        <ReactQuill
													          value={getMessage}
													          onChange={handleChange}
													          modules={modules}
													          formats={formats}
													          placeholder="How can we help you?"
													          style={{minHeight: "150px"}}
													        />
													        <CustomToolbar/>
													       </div> 
											       }
										      </Col>


  									          <Col sm="9">										        
				                                   <textarea 
				                                   name="message"
				                                   value={getMessage}
				                                   hidden
				                                   >				                                   	
				                                   </textarea>
										      </Col>
							      </Form.Group>


							      <Form.Group className="py-4" controlId="formBasicCheckbox" style={{display: "flex", flexDirection: "row", gap: "5px", justifyContent: "center"}}>
							        	<Form.Check type="checkbox" checked={getTerms} onChange={() => checkBox(waitTerms)}/>
							        	<p style={{fontSize: "0.9rem"}}>Agree to the terms and conditions of <a className="text-decoration-none" href="/data-privacy">Privacy Policy</a></p>
							      </Form.Group>


							      <div className="text-center">
							      		{
							      			getTerms == true ?
									        <Button type="submit" value="Send" style={{border: "none",boxShadow: "none", fontFamily: "Inter", whiteSpace: "nowrap"}}
									        className="SubmitButton m-3">SUBMIT</Button>
									        :
									   		<Button type="submit" value="Send" style={{border: "none",boxShadow: "none", fontFamily: "Inter", whiteSpace: "nowrap"}}
									        className="SubmitButton m-3" disabled>SUBMIT</Button>
							      		}

							      </div>
					    </Form>
					</Container>
				</div>
		</div>
		)
}
