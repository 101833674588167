import {Col, Row, Container, Button, Card} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Banner from '../components/Banner';
import WhoWeAre from '../components/WhoWeAre';
import OurServices from '../components/OurServices';
import AboutUs from '../components/AboutUs';
import Cookies from '../components/Cookies'
import LetsDoThis from '../components/LetsDoThis';
import Service1 from '../images/services (1).jpg'  
import Service2 from '../images/services (2).jpg'
import Service3 from '../images/services (3).jpg'
import Service4 from '../images/services (4).jpg'
import ContactUs from '../components/ContactUs';

export default function Home () {

    const [getServices, setServices] = useState([])


    const data = [
    {
      imgUrl: `${Service1}`,
      title: 'WEB DEVELOPMENT',
      description: 'Start and grow your business with your own website, tool and app.' 
    },
    {
      imgUrl: `${Service2}`,
      title: 'VIDEO and PHOTO PRODUCTION',
      description: 'Stand out with amazing visuals to send your message across.' 
    },
    {
      imgUrl: `${Service3}`,
      title: 'SOCIAL MEDIA MANAGEMENT',
      description: 'Engage your audience with content tailor-fit to your brand.' 
    },
    {
      imgUrl: `${Service4}`,
      title: 'COMMUNITY MANAGEMENT',
      description: 'Nurture your community as they grow with you through engagements.' 
    },
    ]


    // cookies
    const cookieStorage =  {
      getItem: (key) => {
        const cookies = document.cookie
        .split(';')
        .map(cookie =>  cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value}), {});
        return cookies[key];
      },
      setItem: (key, value) => {
        document.cookie = `${key}=${value}; expires=Thu, 30 Dec 2027 12:00:00 UTC";`;
      },
    }


    const storageType = cookieStorage;
    const consentPropertyName =  'PDBSI_consent';
    const consent = storageType.getItem(consentPropertyName)
    
    const saveToStorage = () => {
      storageType.setItem(consentPropertyName, true)  
      setModalShow(false)
    }
    
    const [modalShow, setModalShow] = useState(false); 

  

  useEffect(() => {

    setServices(data.map(service => {
    return (
        <OurServices serviceProp={service}/>
      )
    }))

    if (consent == undefined) {
      setModalShow(true)
    } else {
      setModalShow(false)
    }

}, [])

  return (
    <div>
      <Cookies show={modalShow} onHide={() => setModalShow(false)} consentProp={() => saveToStorage()}/>
      <Banner />
      <WhoWeAre />
          <Container fluid className="midnightBlue" id="services">
            <h1 className="pt-5 ourServices">Our Services</h1>
              <Container className="pb-5">
                <Row>
                  {getServices}
                </Row>
              </Container>
          </Container>
      <AboutUs />
      <LetsDoThis />
      <ContactUs/>
    </div>
  );
};


