import { Container, Nav, Navbar } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CompanyLogo from '../images/CompanyLogo.png'
import '../Styles/NavBar.css'

export default function AppNavBar() {

    const [isHovering, setIsHovering] = useState(false);



  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const color = () => {
    if(isHovering){
      return '#F8931F'
    }
    else {
      return '#000000'
    }
  }


	return (
	<Navbar expand="lg">
        <Navbar.Brand as={Link} to="/" ><img 
            className="company-logo"
            src={CompanyLogo}
          /></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end nav-pills text-center">
          <div className="px-3 links" >
          <Nav.Link style={{fontSize: "16px", fontWeight: "700", fontFamily: "Montserrat"}} href="/#home" >HOME</Nav.Link>
          </div>
          <div className="px-3 links" >
          <Nav.Link style={{fontSize: "16px", fontWeight: "700", fontFamily: "Montserrat"}} href="/#services">OUR SERVICES</Nav.Link>
          </div>
          <div className="px-3 links" >
          <Nav.Link style={{fontSize: "16px", fontWeight: "700", fontFamily: "Montserrat"}} href="/#about">ABOUT</Nav.Link>
          </div>
          <div className="px-3 links" >
          <Nav.Link style={{fontSize: "16px", fontWeight: "700", fontFamily: "Montserrat"}}  href="/#contactUs">CONTACT US</Nav.Link>
          </div>
        </Navbar.Collapse>
    </Navbar>
		)
}

