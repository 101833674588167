import { useNavigate } from 'react-router-dom';
import ShakeHands from '../images/ShakeHands.png'

export default function LetsDoThis() {

const navigate = useNavigate()

// const navigateTo = () =>  {
// 	navigate("/contact-us")
// }

	return (

			   <div className="letsDoThis-div pb-0">
		          <img className="letsDoThis-pic" src={ShakeHands} />
		          <div className="letsDoThis-text">
		            <h1 className="letsDoThis-title pt-3">
		              Let’s do this <br />
		              together.
		            </h1>
		            <h1 className="letsDoThis-subTitle" >LOCATION:</h1>
		            <a className="letsDoThis-info " href='https://goo.gl/maps/VXhBkwb1rkkeZdYY9' target="_blank" >Quezon City, Philippines</a>
		            <h1 className="letsDoThis-subTitle"  >CONTACT:</h1>
		            <a className="letsDoThis-info" href="#contactUs">hello@parazodigital.com</a >
		          </div >
		      </div>

		)
}