import List from '../components/List'
import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import '../Styles/PrivacyPolicy.css'

export default function PrivacyPolicy () {

	const [getPrivacyList, setPrivacyList ] = useState([])
	const [getPersonalDataList, setPersonalDataList ] = useState([])

	const parazodigital = "/"


	const privacyList = [
		"the types of personal data we collect;",
		"our purpose for processing your personal data;",
		"our basis for processing your personal data;",
		"how we store and protect your personal data;",
		"how long are we keeping your personal data;",
		"your data subject rights and how you can exercise them; and",
		"whether we intend to share your personal data to another entity."
	]

	const personalData = [
		"Name",
		"Corporation or Business Name",
		"Birthday",
		"Email Address",
		"Mobile Number",
		"Location",

	]



	
	const data = (setter, list) => {
		setter(list.map(lists => {
		    return (
		        <List listProp={lists}/>
		      )
		    }))
	}

	  useEffect(() => {

	  	data(setPrivacyList, privacyList)
	  	data(setPersonalDataList, personalData)

}, [])



	return (


<div style={{paddingBottom: "0px", marginBottom: "0px"}}>
<div style={{display: "flex", flexDirection: "row", padding: "0rem 0rem 0rem 1rem"}}>		
<Container>
<div>
	<h1>Privacy Policy</h1>

	<p>Privacy Policy of <span className="highlight">Parazo Digital Business Solutions, Inc</span> | <a href={parazodigital}>www.parazodigital.com</a></p>
	
	<p>	
		<span className="highlight">Parazo Digital Business Solutions,Inc (PDBSI)</span>, is a corporation duly established and organized in the Philippines.
	 	In its 4 years of existence, has created and managed websites and online platforms, provided online marketing,
	  	online content and community management services.
	</p>
	
	<p>
		As one who sees value in its client’s trust and as an advocate of data privacy, we are committed to protect and
		respect your data privacy as we process your personal information and sensitive personal information (“Personal Data”)
		only for purposes herein mentioned.
	</p>

	<p>
		This privacy notice explains how your personal information is collected, used, stored, disclosed and disposed, specifically you will find here:
	</p>

	<ul>
		{getPrivacyList}
	</ul>
</div>


<div>
	<h3>Definition of Terms</h3>

	<p>
		Personal Information - is information or set of information that can be used to identify an individual such as name, mailing address, email address,
		telephone number, mobile number, a photo or a video image of a person and other personal details.
	</p>

	<p>
		Sensitive Personal Information – an information about individual’s race, ethnic origin, marital status, age, color, and religious, philosophical or
		political affiliations; individual’s health or education; about any criminal, civil or administrative proceeding of an individual; unique government
		issued identifiers; and those established by law as classified.
	</p>

	<p>
		Processing of personal data - refers to any operation or any set of operations performed upon personal information including, but not limited to,
		the collection, use, storage, disclosure or disposal.
	</p>
	
</div>

<div>
	<h3>The Personal Data We collect</h3>

	<h5>As you inquire we collect the following information:</h5>

	<ul>
		{getPersonalDataList}
	</ul>
</div>


<div>
	<h3>How We Collect Your Personal Data</h3>
<ul>
	<p>You contacted us to inquire about our products and services through our website.</p>
	<p>You availed of our products and services.</p>
	<p>You subscribe to our e-newsletter and promotional materials.</p>
	<p>You joined our online promos and marketing activities.</p>
	<p>We also receive personal data indirectly, in the following in cases:</p>
	<p>Our service was engaged and in the course of the service agreement, your personal data were shared by our client with your consent; or</p>
	<p>When you visit our website which uses cookies to analyze website traffic and optimize your website experience.</p>
</ul>
</div>


<div>
	<h3>Our Reason For Collecting Your Personal Data</h3>

	<p>Any personal data collected by us will be used solely for their intended purpose/s which are:</p>

	<ol>
		<li>Submitted webform</li>
		<p>To allow communication between us to answer your queries or address initiated through the submission of your webform on our website.</p>
		<li>Other Purposes</li>
		<p>To market our products and services;</p>
		<p>To provide you updates on our products and promotions;</p>
		<p>To document our free trainings, workshops and/or other promotional activities;</p>
		<p>To improve our website and social media pages and the delivery of our services;</p>
		<p>To conduct internal statistical analysis and meet our reporting requirements for our market study and research;</p>
		<p>To comply with the requirements of all applicable laws, rules and regulation; and/or</p>
		<p>To protect lawful rights and interests in court proceedings and to establish, exercise or as a defense from legal claims</p>
	</ol>
</div>



<div>
	<h3>Our basis of processing your personal data</h3>

	<ol>
		<li>Your consent as our basis.</li>
		<p>We will only use the personal data we collect from you for the following purposes only if you provided your consent:</p>
		<ul>
			<li>to facilitate your subscription to our product updates and e-newsletters; and</li>
			<li>to send you marketing materials and promotion updates via e-mail.</li>
		</ul>


		<li>The needs of the contract, our legitimate business interest, or our need to comply with our legal obligation as our basis.</li>
		<p>The following are the circumstances when our collection of your data are based on contracts, legitimate interest and legal obligation compliance:</p>
		<ul>
			<li>You contacted us to inquire about our services;</li>
			<li>You submitted your email and query through our website;</li>
			<li>You availed of our products and services.</li>
		</ul>
	</ol>	
</div>


<div>
	<h3>Children’s Personal Data</h3>
	<p>
		<span className="highlight">PDBSI</span> does not provide services nor do we market to minor children, below 18 years old. Thus, we do not directly and proactively collect personal data from children. 
		If you are a minor, please do not submit any personal information through our website.
	</p>

	<p>
		Parents and legal guardians are encouraged to monitor their children’s online activities.
	</p>

	<p>
		How we protect and store your personal data
	</p>

	<p>
		Any personal data collected by us shall be secured through implementation of reasonable and appropriate organizational,
		physical and technical security measures intended for the protection of personal information against any accidental or unlawful destruction, 
		alteration and disclosure, as well as against any other unlawful processing in accordance with the Data Privacy Act of 2012 (DPA) and its related issuances.
	</p>

	<p>
		Your personal information is securely stored in our computer systems and a third-party cloud service provider. Physical documents on the other hand, 
		are security stored and kept within our office premise. We also use Google Workspace for our email and other office productivity applications. 
		This means that the personal data we hold may be transferred to, or accessed from, countries other than the Philippines.
	</p>

	<p>Your personal information is protected by us through: </p>
	<ul>
		<li>Implementation of safeguards to protect our computer network against accidental, unlawful or unauthorized usage or interference with or hindering of their functioning or availability;</li>
		<li>Strict implementation of data privacy and data security policy within the organization;</li>
		<li>Conduct of regular monitoring for security breaches and a process for taking preventive, corrective and mitigating action against security incidents that can lead to a security breach;</li>
		<li>Ensuring that our third-party vendor/s or partner/s, who are processing personal information for us, also treat your personal data with the highest degree standard for data protection; and</li>
		<li>To ensure that our employees, agents or who are involved in the processing your personal data shall operate and hold them under strict confidentiality.</li>
	</ul>
	<p>
		We ensure that our third-party data processors can meet our privacy and security requirements. We are satisfied that they have adequate security and privacy safeguards in place 
		to protect information it holds on our behalf.
	</p>
</div>



<div>
	<h3>How long do we keep your personal data</h3>
	<p>
		Your personal data are retained only for a period necessary for us to fulfill the purposes for which they were collected, such as to process and facilitate your orders, 
		execute the terms and conditions of our sales or service contracts or to fulfill legitimate business interests.
	</p>
	
	<p>
		We also retain your data for as long as required by applicable laws, rules or regulation.
	</p>

	<p>
		We will then dispose your information in a secure manner after the required retention period.
	</p>
</div>


<div>
	<h3>Our Cookies Policy</h3>

	<p>
		Cookies are small text files that are placed in your browser by the websites you visit. They are widely used to help users navigate websites efficiently, 
		to perform certain functions on the sites, and/or to provide site owners with information about how their sites are used.
	</p>

	<p>
		We use cookies on our site where they are required for particular features to work, to help us improve our website’s performance, to tell us how many people come 
		to our website and to optimize how fast our site loads.
	</p>

	<p>
		We also use tracking cookies through Google Analytics and Facebook Pixel to send metrics related to third party integrations. These cookies are used to collect 
		information about how and when visitors use our website. 
	</p>

	<p>
		Only aggregated and anonymized data are collected through these cookies, which means data collected does not directly identify anyone, 
		but only determines the number of visitors to the website.
	</p>

	<p>
		You have control over the use of these cookies, you can delete or disable them through your web browser settings. 
		If you do choose to set your browser to disable all cookies, this may affect your ability to use some of the features on our website.
	</p>
</div>

<div>
	<h3>When we share your personal data</h3>

	<p>
		We do not generally share your personal information with third parties except those who are providing service to us. 
		These services are covered by contracts which means that they only process, share, retain and secure your personal data under our control and upon our instructions.
	</p>

	<p>We may also share your personal information in the following circumstances</p>

	<ul>
		<li>If necessary to appropriately respond to your inquiry;</li>
		<li>If we are legally obliged to do so, (for example we may 
			share personal information with the law enforcement agencies 
			or another government regulatory bodies, if required by law or 
			upon court order such as when we are needed to assist with 
			the investigation of a criminal offence);</li>
		<li>To comply with the requirements of all applicable laws, rules and regulation; or</li>
		<li>To protect lawful rights and interests in court proceedings and to establish, exercise or as a defense from legal claims.</li>
	</ul>
	<p>In any scenario, we will ensure that we have the lawful basis for sharing your personal data.</p>
</div>

<div>
	<h3>Your data privacy rights</h3>

	<p>Under the Data Privacy Act of 2012, you have the following rights:</p>
	<ul>
		<li><b>Right to be informed.</b></li>
		<p>
        	You have the right to be informed of how your data is collected, used, stored, shared and disposed and be furnished 
        	with such information before you disclose your information. You can learn how we process your data through this Privacy Notice.
		</p>
		
		<li>Right to object.</li>
		<p>
			You have the right to object to us processing your personal information when we asked for your consent, that includes processing for promotional or marketing purposes.
		</p>

		<li>Right of access.</li>
		<p>
			You have the right to ask us for information regarding the activities conducted on your personal information.
		</p>

		<li>Right to rectification.</li>
		<p>
			You have the right to ask us to rectify your personal information that you think is inaccurate, outdated or false. You also have the right to ask us to complete information you think is incomplete.
		</p>

		<li>Right to erasure or blocking.</li>
		<p>
			You have the right to ask us to erase your personal information in certain circumstances or have us stop using your data for specific purpose/s.
		</p>

		<li>Right to data portability.</li>
		<p>
			You have the right to ask that we transfer the personal information you gave us to another organization, or to you, in certain circumstances.
		</p>

		<li>Right to damages.</li>
		<p>
			If you think that your rights as a data subject has been violated, or if you sustained damages due to any inaccurate, incomplete, outdated, false, 
			unlawfully obtained or unauthorized use of your personal information, you may file a complaint with the National Privacy Commission.
		</p>
	</ul>
</div>


</Container>
<Container className="w-50 second-container">
{/*<table style={{border: "1px solid", width: "100%", maxHeigt: "30vh"}}>
	<tr>
		<tr>Privacy Policy</tr>
	</tr>
</table>*/}
</Container>
</div>



<div className="midnightBlue fs-13" style={{color: "white", paddingBottom: "0px"}}>
<h3 className="text-center p-3" >How to exercise these rights or escalate any concerns</h3>
<div className="flexRow">
<Container fluid className="flex-column" style={{paddingBottom: "0px"}}>

	
<Container>
	<p className="highlight">
		PDBSI is the controller for the personal data we collected from you. You can contact us through these channels:
	</p>
<div className="flexRow">
	<p>E-mail: support@parazodigital.com</p>

	<p>Phone: + 63 917 635 0283</p>
</div>
</Container>

<Container>
	<p className="highlight">
		You can also file a complaint to the National Privacy Commission if you are unhappy with how we handled your concern or how we used your personal information through:
	</p>

	<p>National Privacy Commission (Complaints and Investigation Division)</p>

	<p>8234-2228 Loc 114</p>

	<p>complaints@privacy.gov.ph</p>

	<p>5th Floor Delegation Building, PICC Complex, Roxas Boulevard</p>	

	<a href="google.com">https://www.privacy.gov.ph/</a>
</Container>	
</Container>
<Container style={{paddingBottom: "0px"}}>
<div>
	<p className="highlight">If you wish to exercise your rights stated above or if you have concerns about our use of your personal data. You can contact us through:</p>
	<div className="flexRow">
		<p>Data Protection Officer</p>
		<p>+ 63 917 635 0283</p>
		<p>privacy@parazodigital.com</p>
	</div>
</div>

<div>		
	<p className="highlight">Parazo Digital Business Solutions Inc.</p>

	<p>Tandang Sora, Quezon City, Philippines 1116</p>
	<p>Changes to this privacy notice</p>
	<p>Our privacy notice is under regular review to make sure it is up to date and accurate.</p>
	<p>Any changes to this privacy notice shall be made without prior consultation and will be posted on this page.</p>
	<p>Published: <u>October 2022</u></p>
</div>	
</Container>
</div>
</div>
</div>

		)
}