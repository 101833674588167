import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"

// Use .env file to hide keys
const firebaseConfig = {
    apiKey: "AIzaSyB7_Zxv9NCR9uDBm6o8DMGcejYZStMMgmU",
    authDomain: "parazo-digital.firebaseapp.com",
    projectId: "parazo-digital",
    storageBucket: "parazo-digital.appspot.com",
    messagingSenderId: "135828116941",
    appId: "1:135828116941:web:08a9ea48fbc7c16e2be084",
    measurementId: "G-SWYZ0ZL8L2"
  };

  const app = initializeApp(firebaseConfig); //Established connection

 export const db = getFirestore(app); // DB connection


// import { initializeApp } from "firebase/app";
// import { getFirestore } from "@firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyAZ_I-1e3nDR5w5rTWT8y2ebNcmCo_Z1Tk",
//   authDomain: "poetic-sentinel-362006.firebaseapp.com",
//   projectId: "poetic-sentinel-362006",
//   storageBucket: "poetic-sentinel-362006.appspot.com",
//   messagingSenderId: "1055978690205",
//   appId: "1:1055978690205:web:c39ab31a64385f27262674",
//   measurementId: "G-9SCPM6M4RZ"
// };

// console.log("Firebase config loaded:", firebaseConfig);

// const app = initializeApp(firebaseConfig); //Established connection

// console.log("Firebase app initialized:", app);

// export const db = getFirestore(app); // DB connection

// console.log("Firestore DB connection established:", db);
