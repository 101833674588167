import { Container, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import ErrorImg from '../images/Error2.png'

export default function NotFound (){

const navigate = useNavigate()
	
const home = () => {
    navigate("/")
}

	return (
	<div style={{backgroundImage: "linear-gradient(90deg, #080548 0%,#000000 47%)", display: "flex", flexDirection: "row"}}>	
		<Container style={{display: "flex", flexDirection: "column", padding: "5% 0% 1.9% 5%", justifyContent: "space-between"}} >
			<div>
				<h1 style={{fontSize: "68px", fontWeight: "700", color: "#f8931f"}}>Lost?</h1>
			</div>
			<div>
				<p style={{color: "white", fontSize: "30px"}}>The page you are looking for does not exist.</p>
				<p style={{color: "white", fontSize: "30px"}}>Just hit the button below to get back on track.</p>
				<p style={{color: "white", fontSize: "30px"}}>Thanks!</p>
			</div>
			<div>
				<Button style={{margin: "3rem 0rem"}} className="BannerButton" onClick={() => home()} >
              		Back To Home
              	</Button>
			</div>
		</Container>
		<div>
			<img  className="errorImg" src={ErrorImg}/>
		</div>
	</div>	
		)
}
