import CEO from '../images/CEO.png'
import CFO from '../images/CFO.png'
import Signature from '../images/Signature.png'
import Sig1 from '../images/Sig1.png'


export default function AboutUs() {

	return (
			 <div className="about-us-div pt-5">
		        <div className="about-us-pic">
		          <div className="about-us-ceo">
		            <img className="ceo"
		              src={CEO}
		            />
		            <img className="signature"  
		            src={Signature} />
		            <h1 className="about-us-title">CEO</h1>
		          </div >
		          <div className="about-us-cfo">
		            <img className="ceo"
		              src={CFO}
		            />
		            <img className="signature-nico"  
		            src={Sig1} />
		            <h1 className="about-us-title">CFO</h1>
		          </div>
		        </div>
		        <div className="aboutUs-text">
		          <h1 id="about" className="aboutUs">About Us:</h1>
		          <div className="div-paragraph">
		            <span className="Highlight"> Parazo Digital Business Solutions Inc. </span>
		              is a complete digital solutions provider for businesses. <br />
		              <br />
		              Our years of experience as a start-up through our e-learning
		              platform,
		              <span className="Highlight"> The Bailiwick Academy </span>
		              , helped us conceptualize, build, manage, market solutions and
		              services at a global scale. We guided a lot of people pursue their
		              dreams of starting and growing their own business. Through this
		              online platform and our
		    
		              campaigns, we have established and nurtured a community of
		              individuals with similar goals that support each other. <br />
		              <br />
		              We are incorporated in Manila, Philippines with a global
		              footprint. We have more than 20 years of experience in the tech
		              and customer service industry, thus, we understand how customer
		              experience impacts
		              <span className="yourSuccess"> YOUR SUCCESS!</span>
		          </div>
		        </div>
		      </div>
		)
}